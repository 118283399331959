import Glide from '@glidejs/glide';

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.glidejshome();
  }

  glidejshome() {
    window.addEventListener('load', () => {
      if (document.querySelector('.glide1') != null) {
        const slider = new Glide('.glide1', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 2,
          slidesToScroll: 1,
          keyboard: true,
          gap: 10,
          bound: true,
          loop: true,
          rewind: true,
          peek: {
            before: 0,
            after: 80,
          },
          breakpoints: {
            1024: {
              perView: 2,
              peek: {
                before: 0,
                after: 80,
              },
              gap: 8,
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 60,
              },
              gap: 2,
            },
          },
        });
        slider.mount();
      }
      if (document.querySelector('.glide2') != null) {
        const slider2 = new Glide('.glide2', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 2,
          slidesToScroll: 1,
          keyboard: true,
          bound: true,
          gap: 10,
          loop: true,
          rewind: true,
          peek: {
            before: 0,
            after: 80,
          },
          breakpoints: {
            1024: {
              perView: 2,
              peek: {
                before: 0,
                after: 80,
              },
              gap: 8,
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 60,
              },
              gap: 2,
            },
          },
        });
        slider2.mount();
      }
      if (document.querySelector('.glide3') != null) {
        const slider3 = new Glide('.glide3', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 1,
          slidesToScroll: 1,
          keyboard: true,
          gap: 7,
          bound: true,
          loop: true,
          rewind: true,
          peek: {
            before: 0,
            after: 350,
          },
          breakpoints: {
            1024: {
              perView: 2,
              gap: 5,
              peek: {
                before: 0,
                after: 0,
              },
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 0,
              },
              gap: 2,
            },
          },
        });
        slider3.mount();
      }
    });
  }
}
